.normalPages {
    width: 100%;
    height: 100%;
    // background-color: yellow;
    text-align: center;

    &__phoneSim {
        display: inline-block;
        width: 30%;
        height: 75%;

        .phoneSim__label {
            margin-top: 2rem;
            font-size: 2rem;
            color: #aaa;
        }
    }
}
