.bg {
    @include fixed-full-screen;

    @include for-non-phone {
        .onlyOnPhone {
            display: none;
        }
    }

    &Section {
        @include fixed-full-screen;
        background: center/cover;
        // opacity: 0;

        .scrollIndicator {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 2vh;
            // background-color: yellow;
            // color: $landing-color-secondary;
            text-align: center;
            opacity: 0.4;

            .text {
                font-size: 1.5rem;
            }

            .downArrow {
                position: relative;
                top: -0.5rem;
                font-size: 2rem;
            }
        }

        &--0,
        &--2,
        &--4,
        &--5,
        &--6 {
            // Home, App, Contact, Profile
            // background-color: red;
            background-image: url(https://storage.googleapis.com/gratia-media/web/images/bg-texture-1-2880x2048.jpg);

            @media only screen and (max-width: $bp-medium) {
                background-image: url(https://storage.googleapis.com/gratia-media/web/images/bg-texture-1-1440x1024.jpg);
            }
        }

        &--1 {
            // Membership
            // background-color: blue;
            background-image: url(https://storage.googleapis.com/gratia-media/web/images/couple-with-candles-2880x1862.jpg);

            @media only screen and (max-width: $bp-medium) {
                background-image: url(https://storage.googleapis.com/gratia-media/web/images/couple-with-candles-1440x931.jpg);
            }

            .fade {
                position: fixed;
                left: 0;
                top: 0;
                width: 100vw;
                height: 65vh;
                background: linear-gradient(#000, #000 25vh, rgba(#000, 0));
            }
        }

        // &--2 {
        //     // App
        //     // background-color: #111;

        //     background-image: url(https://storage.googleapis.com/gratia-media/web/images/bg-texture-1-2880x2048.jpg);

        //     @media only screen and (max-width: $bp-medium) {
        //         background-image: url(https://storage.googleapis.com/gratia-media/web/images/bg-texture-1-1440x1024.jpg);
        //     }

        //     // background-color: darkgreen;
        //     // background-image: url(https://storage.googleapis.com/gratia-media/web/images/Black%20Texture%20Wallpaper%20Pc%20Wallpaper%20WallpaperLepi.jpg);
        //     // .phones {
        //     //     position: fixed;
        //     //     left: 45vw;
        //     //     top: 10rem;
        //     //     width: calc(55vw - 5rem);
        //     //     height: calc(100vh - 15rem);
        //     //     // background-color: red;
        //     //     display: flex;

        //     //     @media only screen and (max-width: $bp-small) {
        //     //         left: 2rem;
        //     //         right: 2rem;
        //     //         width: auto;
        //     //         top: auto;
        //     //         bottom: 2rem;
        //     //         // height: auto;
        //     //     }

        //     //     .phone {
        //     //         flex-grow: 1;
        //     //         background: no-repeat center top/contain;

        //     //         @media only screen and (max-width: $bp-small) {
        //     //             background-position: center bottom;
        //     //         }
        //     //     }

        //     //     &--m {
        //     //         .phone {
        //     //             &--1 {
        //     //                 background-image: url(https://storage.googleapis.com/gratia-media/web/images/Phone-Male1-698x1388.png);
        //     //             }

        //     //             &--2 {
        //     //                 margin-left: 3rem;
        //     //                 background-image: url(https://storage.googleapis.com/gratia-media/web/images/Phone-Male2-698x1388.png);
        //     //             }
        //     //         }
        //     //     }

        //     //     &--f {
        //     //         .phone {
        //     //             &--1 {
        //     //                 background-image: url(https://storage.googleapis.com/gratia-media/web/images/Phone-Female1-698x1388.png);
        //     //             }

        //     //             &--2 {
        //     //                 margin-left: 3rem;
        //     //                 background-image: url(https://storage.googleapis.com/gratia-media/web/images/Phone-Female2-698x1388.png);
        //     //             }
        //     //         }
        //     //     }
        //     // }
        // }

        &--3 {
            // Events
            .sideImg {
                &__img {
                    position: fixed;
                    left: 0;
                    top: $nav-height;
                    width: 55vw;
                    bottom: 0;
                    background: center/cover;

                    background-image: url(https://storage.googleapis.com/gratia-media/web/images/glasses-1600x1065.jpg);

                    @media only screen and (max-width: $bp-medium) {
                        background-image: url(https://storage.googleapis.com/gratia-media/web/images/glasses-1200x799.jpg);
                    }

                    @media only screen and (max-width: $bp-small) {
                        top: 8rem;
                        width: 60vw;
                    }
                }

                &__fade {
                    position: fixed;
                    left: 25vw;
                    top: $nav-height;
                    width: 75vw;
                    bottom: 0;
                    background: linear-gradient(
                        to right,
                        rgba(#000, 0),
                        #000 30vw,
                        #000
                    );

                    @media only screen and (max-width: $bp-small) {
                        left: 10vw;
                        width: 85vw;
                        top: 6rem;
                        background: linear-gradient(
                            to right,
                            rgba(#000, 0),
                            #000 50vw,
                            #000
                        );
                    }
                }
            }
            // background-color: orange;
        }

        // &--4 {
        //     // Contact
        //     // background-color: lightblue;
        //     background-image: url(https://storage.googleapis.com/gratia-media/web/images/bg-texture-1-2880x2048.jpg);

        //     @media only screen and (max-width: $bp-medium) {
        //         background-image: url(https://storage.googleapis.com/gratia-media/web/images/bg-texture-1-1440x1024.jpg);
        //     }
        // }
    }
}
