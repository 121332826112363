.editBlock {
    &__fld {
        padding: 1rem 0;

        &:nth-child(odd) {
            background-color: #d8d8d8;
        }

        &:nth-child(even) {
            background-color: #e8e8e8;
        }
    }

    &--debug {
        border: solid 2px blue;
    }
}
