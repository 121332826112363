.comingSoon {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    cursor: default;

    font-family: $font-sans-1;
    font-size: 5vw;
    letter-spacing: 1vw;
    color: $landing-color-primary-dark3;

    @media only screen and (max-width: $bp-small) {
        font-size: 10vw;
    }

    &__static {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__pulse {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        &--text {
            animation: pulse 3s ease-in infinite;
        }
    }
}

@keyframes pulse {
    0% {
        opacity: 0.5;
        transform: scaleX(1) scaleY(1);
    }
    100% {
        opacity: 0;
        transform: scaleX(3) scaleY(6);
    }
}
