.phoneVerify {
    // background: #555;

    // &__input {
    //     margin-bottom: 2rem;
    // }
    display: flex;
    flex-direction: column;
    height: 100%;

    // &__top {
    //     flex-grow: 1;
    //     background-color: yellow;
    // }

    // &__bottom {
    //     background-color: blue;
    // }

    &__legal {
        margin-top: 4rem;
        padding: 0 3rem;
        text-align: left;
        @include fsr-14;
        font-style: italic;
        color: $landing-color-primary-dim-2;
    }

    &__verified {
        margin-top: 2rem;
        color: $landing-color-secondary;
    }

    &__verifying {
        margin-top: 2rem;

        &--requestNew {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    &__optIn {
        margin-top: 4rem;

        &--question {
            @include fsr-18;
            font-weight: 500;
        }

        &--buttonRow {
            margin-top: 2rem;
            display: flex;
            justify-content: center;
        }
    }

    &__codeInput {
        margin-top: 2rem;
    }

    input {
        width: 100%;
        height: 5rem;
        padding: 0 1rem;
        outline: none;
        border-radius: 1rem;
        // border: solid 1px $color-primary-1;
        font-size: 1.5rem;
        // color: $color-primary-1;

        border: solid 1px $landing-color-primary-dim-3;
        background-color: #111;
        color: $landing-color-primary;
    }

    .fancyButton {
        // background-color: blue;
        margin-top: 4rem;
        font-family: $font-sans-1;
        @include fsr-14;
        background-color: $landing-color-secondary;
        color: $landing-color-primary;
    }

    // .button {
    //     margin-top: 4rem;
    //     display: inline-block;
    //     @include landing-button-1;

    //     &--disabled {
    //         @include landing-button-disabled-1;
    //     }
    // }

    &__errors {
        margin-top: 2rem;

        .error {
            @include error-text;
        }
    }
}
