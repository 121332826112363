.ansWeightsEditor {
    // background: yellow;

    &__ansWeights {
        display: grid;
        grid-template-columns: 1fr min-content;
        row-gap: 2rem;

        .text {
            &__0 {
                font-size: 1.6rem;
            }

            &__1 {
                font-size: 1.2rem;
            }
        }
        
        input {
            width: 10rem;
            height: 3rem;
        }
    }
}
