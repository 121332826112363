.dateTime {
    // background-color: yellow;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__feedback {
        margin-top: 2rem;
        color: $landing-color-primary-dim-2;
    }

    &__debug {
        font-size: 1rem;
    }

    .react-calendar {
        border-radius: 1rem;
    }

    .react-calendar__tile {
        border-radius: 0.5rem;

        &--now,
        &--active,
        &--hasActive {
            background: $color-accent-1 !important;
            color: $color-accent-2;
        }

        &--now {
            background: rgba($color-accent-1, 0.5) !important;
        }

        &--range,
        &--rangeStart,
        &--rangeEnd,
        &--rangeBothEnds {
            background: rgba($color-accent-1, 0.5);
            // background: yellow;
        }
    }

    &__age {
        margin-top: 0.5rem;
        text-align: center;
        font-size: 1.2rem;
    }
}
