.nav {
    $n: &;
    $admin-mode-color: #999;

    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    // height: 9vh;
    // max-height: 8rem;
    height: $nav-height;

    color: $landing-color-primary;

    @include for-non-phone {
        .onlyOnPhone {
            display: none;
        }
    }

    @include for-phone-portrait {
        width: 25vw;
        height: 5rem;
        left: auto;
        right: 1rem;
        // padding-right: 0.8rem;
        // justify-content: flex-end;
        // align-items: flex-start;

        .notOnPhone {
            display: none;
        }
    }

    // background-color: rgba(yellow, 0.2);

    // @media only screen and (max-width: $bp-small) {
    //     display: none;
    // }

    // &__bg {
    //     @include set-pos-ltwh(absolute, 0, 0, 100%, 100%);
    //     // background: rgba(black, 0.4);
    //     // transform: translateY(-100%);
    //     // transition: transform 1s;

    //     // &--show {
    //     //     transform: translateY(0);
    //     // }
    //     display: none;
    // }

    &__contents {
        @include set-pos-ltwh(absolute, 0, 0, 100%, 100%);

        padding: 0 3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        #{$n}--admin & {
            color: $admin-mode-color;
        }

        .logo {
            display: flex;
            align-items: baseline;
            @include font-serif-1;

            @include for-phone-portrait {
                display: none;
            }

            &__logo {
                @include fsr-34;
                user-select: none;
                cursor: default;
            }

            &__caption {
                font-weight: 400;
                margin-left: 1.6rem;
                @include fsr-20;
                white-space: nowrap;
                user-select: none;
                cursor: default;
            }
        }

        .links {
            @include fsr-15;
            display: flex;
            align-items: center;

            .titles {
                display: flex;
                color: $landing-color-primary-dim;
                // color: yellow;
                transition: opacity 1s;

                #{$n}--admin & {
                    color: $admin-mode-color;
                }

                .title {
                    position: relative;
                    margin: 0 2rem;
                    user-select: none;
                    cursor: pointer;

                    &:last-child {
                        margin-right: 0;
                    }

                    &--sel {
                        // font-weight: bold;
                        color: $landing-color-primary-highlight;
                    }
                }

                @include for-phone-portrait {
                    display: none;
                }

                &--hide {
                    opacity: 0;
                }
            }

            .dropdown {
                margin-left: 4rem;
                transition: opacity 1s;

                @include for-phone-portrait {
                    display: none;
                }

                &--hide {
                    opacity: 0;
                }
            }

            .vertBar {
                margin: 0 3rem;
                width: 1px;
                height: 3rem;
                // background-color: yellow;
                background-color: $landing-color-secondary;
                transition: opacity 1s;

                @include for-phone-portrait {
                    display: none;
                }

                &--hide {
                    opacity: 0;
                }
            }

            .button {
                @include landing-button-1;

                @include for-phone-portrait {
                    // display: none;
                    margin-top: 0.5rem;
                    min-width: 0;
                    // max-width: 5rem;
                    padding: 0.6rem 1rem;
                    height: auto;
                    text-align: center;
                    font-size: 1.2rem;
                    line-height: 1.3rem;
                    border: solid 1px $landing-color-secondary;
                    background-color: rgba(black, 0.3);
                    // color: $landing-color-primary;
                }

                &--drawAttention {
                    // background-color: blue;

                    transform: scale(1);
                    animation: pulsing 1s infinite ease-in-out;
                }

                &--hideBtnBG {
                    background-color: transparent;
                    cursor: default;
                }
            }
        }

        .adminSwitch {
            margin-right: 3rem;

            // @include for-phone-portrait {
            //     display: none;
            // }
        }
    }

    .underline {
        position: absolute;
        // left: 0;
        bottom: 2rem;
        // width: 100px;
        height: 2px;
        // background-color: yellow;
        background-color: $landing-color-secondary;
        transition: opacity 1s;

        &--hide {
            opacity: 0;
        }
    }

    @keyframes pulsing {
        0% {
            transform: scale(0.95);
            // box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
            box-shadow: 0 0 0 0 #443930;
        }

        50% {
            transform: scale(1);
            // box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 1rem #443930;
        }

        100% {
            transform: scale(0.95);
            // box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 #443930;
        }
    }
}
