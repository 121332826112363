.popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    &__bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.5;
    }

    &__contents {
        position: relative;
        max-width: 40rem;
        padding: 4rem 8rem;
        border-radius: 2rem;
        // background-color: white;
        background-color: $app-color-bg;
        color: #bbb;
        font-size: 1.6rem;

        .button {
            padding: 1rem 3rem;
            border-radius: 8px;
            border: solid 1px black;
            background-color: #f0f0f0;
        }
    }
}
