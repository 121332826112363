.fancyInput__text,
.fancyInput__password {
    position: relative;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;

    $spacing-text-above-input: -2.2rem;
    $font-size-text-above-input: 1.4rem;

    &--input {
        grid-area: 1 / 1 / 2 / 2;
        z-index: 1;
        caret-color: #333;
    }

    &--label {
        position: absolute;
        left: 1rem;
        top: $spacing-text-above-input;
        font-size: $font-size-text-above-input;
        transition: all 0.3s;
    }

    &--input:placeholder-shown + &--label {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-$spacing-text-above-input);
    }

    // &--overlay {
    //     grid-area: 1 / 1 / 2 / 2;
    //     z-index: 2;
    //     justify-self: end;
    //     align-self: center;
    //     // position: absolute;
    //     // height: 100%;
    //     // display: flex;
    //     // align-items: center;

    //     font-family: $font-sans-1;
    //     font-size: 1.6rem;

    //     font-weight: bold;
    //     // color: $color-primary-dark2;
    //     // color: red;
    //     color: #ed4337;

    //     &--staticText {
    //         margin-left: 1rem;
    //         // top: 0;
    //     }

    //     &--status {
    //         margin-right: 2rem;
    //         // top: 0;
    //     }
    // }

    &--error {
        position: absolute;
        right: 0;
        top: $spacing-text-above-input;
        padding: 0.2rem 1rem;
        border-radius: 0.4rem;
        font-size: $font-size-text-above-input;
        // font-weight: bold;
        transform: translateY(-$spacing-text-above-input);
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;

        &.show {
            opacity: 1;
            visibility: visible;
            transform: translateY(-0.2rem);
        }
    }
}

.fancyInput__checkbox {
    display: flex;
    align-items: center;
    transition: opacity 0.3s;
    cursor: default;

    &--label {
        user-select: none;

        &.disabled {
            cursor: default;
        }
    }

    &--box {
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        cursor: pointer;

        &.disabled {
            cursor: default;
        }
    }

    &.disabled {
        opacity: 0.3;
    }
}
