.spinner {
    display: inline-block;
    font-size: 10px;
    // margin: 50px auto;
    text-align: center;
    // text-indent: -9999em;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    background: white;
    // background: -moz-linear-gradient(left, white 10%, rgba(255, 255, 255, 0) 42%);
    // background: -webkit-linear-gradient(left, white 10%, rgba(255, 255, 255, 0) 42%);
    // background: -o-linear-gradient(left, white 10%, rgba(255, 255, 255, 0) 42%);
    // background: -ms-linear-gradient(left, white 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, white 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    // -webkit-animation: load3 0.8s infinite linear;
    animation: load3 0.8s infinite linear;
    // -webkit-transform: translateZ(0);
    // -ms-transform: translateZ(0);
    transform: translateZ(0);

    &.hide {
        opacity: 0;
    }
}

// .spinner:before {
//     width: 50%;
//     height: 50%;
//     background: white;
//     border-radius: 100% 0 0 0;
//     position: absolute;
//     top: 0;
//     left: 0;
//     content: '';
// }

// .spinner:after {
//     background: #555;
//     width: 75%;
//     height: 75%;
//     border-radius: 50%;
//     content: '';
//     margin: auto;
//     position: absolute;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
// }

@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
