.page--diag {
    .panel--main {
        width: max-content;
        font-size: 1.6rem;

        &__indicator {
            margin-bottom: 1rem;
        }

        button {
            display: block;
            margin: 1rem 0;
            padding: 1rem 2rem;
            width: 100%;
            font-size: inherit;
            outline: none;
            border: solid 1px $button-border-color-1;
            border-radius: 1rem;
            background-color: $color-transparent-white-mid;
        }
    }
}
