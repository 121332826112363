.surveyItemOption {
    display: flex;
    justify-content: center;
    align-items: center;

    // @include fsr-14;
    border-color: $landing-color-primary-dim-3;
    @include fsr-14;
    font-weight: 500;
    background-color: #111;
    color: $landing-color-primary;

    user-select: none;
    cursor: pointer;

    &.small {
        height: 4.8vmin;

        @include for-phone-portrait {
            height: 3.8rem;
        }
    }

    &.medium {
        height: 6vmin;

        @include for-phone-portrait {
            height: 5rem;
        }
    }

    &.large {
        height: 8vmin;

        @include for-phone-portrait {
            height: 7rem;
        }
    }

    .text {
        .text0 {
            // font-size: 1.6rem;
            font-weight: 500;
        }

        .text1 {
            // font-size: 1.4rem;
            // font-weight: normal;
            color: $landing-color-primary-dim-2;
        }
    }

    &--sel {
        background-color: $landing-color-secondary-dim !important;

        .text1 {
            color: $landing-color-primary-dim !important;
        }
    }

    &__debug {
        // position: absolute;
        // left: 0;
        // top: 0;
        font-size: 1.2rem;
        font-weight: normal;
        color: #999;
    }
    // background-color: yellow;

    &--single {
        // margin-bottom: 2rem;
        padding: 1rem 2rem;
        // text-align: center;
        border-radius: 1rem;
        border: solid 1px $landing-color-secondary;
        // background-color: white;
        // font-size: 1.7rem;
        // font-weight: bold;
    }

    &--xor {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        &:not(:first-child) {
            border-left: solid 1px black;
        }

        .option__text {
            font-size: 1.7rem;

            .text1 {
                font-size: 1.4rem;
            }
        }
    }
}
