.surveyItem {
    // position: relative;
    // height: 100%;
    &:not(:last-child) {
        margin-bottom: 2rem;
    }

    &--debug {
        // position: absolute;
        // left: 0;
        // bottom: 0;
        margin: 1rem;
        border: solid 1px green;

        .debugText {
            font-size: 1.2rem;
            font-weight: normal;
            color: #999;
        }
    }

    &__topLabels {
        margin-left: 1rem;
        margin-bottom: 0.5rem;
        display: flex;
        // justify-content: space-around;
        @include fsr-16;
        font-weight: 500;
    }

    &--textInput {
        &__topLabel1 {
            margin-bottom: 0.5rem;
            text-align: center;
            font-size: 1.8rem;
        }

        input {
            width: 100%;
            height: 5rem;
            padding: 0 1rem;
            outline: none;
            border-radius: 1rem;
            // border: solid 1px $color-primary-1;
            @include fsr-15;
            // color: $color-primary-1;

            border: solid 1px $landing-color-primary-dim-3;
            background-color: #111;
            color: $landing-color-primary;
        }
    }

    &--textBox {
        textarea {
            width: 100%;
            height: 15rem;
            outline: none;
            border-radius: 1rem;
            border: solid 1px $color-primary-1;
            font-size: 1.5rem;
            color: $color-primary-1;
        }
    }

    &--noticeText {
        padding: 2rem 5rem 0 5rem;
        white-space: pre-line;
        font-size: 1.8rem;
    }

    &--single {
        .optionBlock {
            display: flex;

            .leftLabel {
                font-size: 1.4rem;
            }

            .options {
                width: 100%;
                // margin-top: 2rem;
                display: grid;
                gap: 1.5rem;
                grid-template-columns: 1fr;

                &.twoCols {
                    grid-template-columns: repeat(2, 1fr);

                    // .text0 {
                    //     // white-space: nowrap;
                    // }
                }

                &.xor {
                    display: flex;
                }
            }
        }
    }

    &__options {
        &--xor {
            // width: 100%;
            // padding: 1rem;
            border: solid 1px black;
            background-color: white;
            border-radius: 1rem;
            display: flex;
            align-items: stretch;
            // flex: 1 0 1rem;
            // justify-content:space-evenly;
            // flex-grow: 1;

            .surveyItemOption {
                padding: 1rem 0;
            }
        }
    }

    &__errors {
        margin-top: 2rem;

        .error {
            @include error-text;
            text-align: center;
        }
    }

    // &__error {
    //     margin-top: 1rem;
    //     padding: 0 5rem;
    //     text-align: center;
    //     font-size: 1.6rem;
    //     font-weight: bold;
    //     color: red;
    // }
}
