.survey {
    &__selector {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        opacity: 0.3;
        text-align: center;
    }

    &Page {
        position: relative;
        height: 100%;
        // padding: 4rem;
        display: flex;
        flex-direction: column;
        // color: $color-primary-1;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 0.8rem;
            background-color: transparent;

            &-thumb {
                background-color: #ccc;
            }
        }
        scrollbar-color: #ccc transparent;

        &__header {
            margin-bottom: 2rem;

            @include for-phone-portrait {
                padding: 0 3rem;
            }

            &--name {
                padding: 0.5rem 0;
                margin-bottom: 2rem;
                text-align: center;
                font-size: 1.6rem;
                border-bottom: dashed 1px #999;
                color: #999;
            }

            &--heading {
                text-align: center;
                @include fsr-18;
                font-weight: 500;
            }

            &--subHeading {
                // margin-top: 0.4rem;
                text-align: center;
                @include fsr-16;
                // opacity: 0.8;
                color: $landing-color-primary-dim-2;
            }
        }

        &__contents {
            flex-grow: 1;
            overflow: auto;
            // background-color: yellow;
        }

        &__controls {
            // margin-bottom: 5rem;
            margin-top: 2rem;
            display: flex;
            // justify-content: center;
            justify-content: space-between;
            @include fsr-14;

            .button {
                cursor: pointer;
                user-select: none;
                @include landing-button-1;
                height: 4rem;
                min-width: 10rem;
                background-color: transparent;
                border: solid 1px #777;

                // &--next,
                // &--done {
                //     margin-left: 4rem;
                // }

                &--exit {
                    opacity: 0.5;
                }

                &.disabled {
                    opacity: 0.3;
                    cursor: default;
                }

                &.hidden {
                    visibility: hidden;
                }
            }

            .indicator {
                padding: 0 2rem;
            }
        }
    }

    &__popup {
        user-select: none;

        .buttons {
            display: flex;
            justify-content: space-around;
            gap: 2rem;

            .button {
                // cursor: pointer;
                @include landing-button-1;
            }
        }
    }

    &__item {
        flex-grow: 1;
    }

    &__noneLoaded {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.3;
        font-size: 3rem;
    }

    &--debug {
        font-size: 1.2rem;
    }
}
