.editCombo {
    width: 100%;
    height: 100%;
    padding: $spacer-1;

    display: grid;
    grid-template-rows: min-content 1fr;
    // grid-template-columns: max-content repeat(2, 1fr);
    grid-template-columns: max-content max-content 1fr;
    gap: $spacer-1;

    border-radius: $border-radius-1;
    // background-color: $section-bg-color-1;
    // border: solid 1px $section-border-color-1;
    background: $color-primary-trn-1;

    .panel {
        display: block;

        &--actionBar {
            grid-area: 1 / 1 / 2 / 4;
        }

        &--chooser {
            grid-area: 2 / 1 / 3 / 2;
            padding: 0;
        }

        &--editor {
            grid-area: 2 / 2 / 3 / 4;
            padding: 0;
        }

        &--invite {
            grid-area: 2 / 2 / 3 / 4;
            padding: 0;
        }

        &--approve {
            grid-area: 2 / 2 / 3 / 4;
            padding: 0;
        }

        &--display {
            grid-area: 2 / 2 / 3 / 4;
            padding: 0;
        }

        &--catalog {
            grid-area: 2 / 2 / 3 / 4;
            padding: 0;
        }
    }

    // &[data-page='user'][data-subpage='edit'] {
    //     .panel {
    //         &--editor {
    //             grid-area: 2 / 2 / 3 / 3;
    //         }
    //     }
    // }

    &[data-page='matching'][data-subpage='matchTasks'] {
        .panel {
            &--editor {
                grid-area: 2 / 2 / 3 / 3;
            }

            &--rtDisplay {
                grid-area: 2 / 3 / 3 / 4;
            }
        }
    }

    &[data-page='onboarding'][data-subpage='survey'],
    &[data-page='onboarding'][data-subpage='surveyPage'],
    &[data-page='onboarding'][data-subpage='surveyItem'] {
        .panel {
            &--editor {
                grid-area: 2 / 2 / 3 / 3;
            }

            &--surveyItem {
                grid-area: 2 / 3 / 3 / 4;
            }
        }
    }

    &[data-page='venue'][data-subpage='edit'] {
        .panel {
            &--editor {
                grid-area: 2 / 2 / 3 / 3;
            }

            &--display {
                grid-area: 2 / 3 / 3 / 4;
            }
        }
    }

    &[data-page='settings'][data-subpage='initialize'] {
        .panel {
            &--initialize {
                grid-area: 2 / 1 / 3 / 4;
            }
        }
    }
}
