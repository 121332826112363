.initScreen {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;

    background-color: #000;
    color: #444;

    font-size: 3rem;

    // display: flex;
    // justify-content: center;
    // align-items: center;
    text-align: center;

    visibility: hidden;
    opacity: 0;
    transition: all 2s;

    &.show {
        visibility: visible;
        opacity: 1;
    }

    .cls-1 {
        fill: #444;
      }
}
