.infoBox {
    // max-height: 600px;
    // overflow: auto;
    // white-space: pre-wrap;
    // font-size: 1.6rem;
    // background-color: yellow;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;

    &__heading {
        // flex-shrink: 1;
        margin-bottom: 1rem;
        font-size: 2.6rem;
        font-weight: bold;
        text-align: center;
    }

    &__contents {
        flex: 1;
        overflow: auto;

        // font-size: clamp(1rem, 1.6vh, 3rem);
        @include fsr-14;

        p:not(:last-child) {
            margin-bottom: 1em;
        }

        h1 {
            font-size: clamp(1rem, 2vh, 3rem);
        }

        h2 {
            font-size: clamp(1rem, 1.6vh, 3rem);
        }

        .actionBtnContainer {
            margin-top: 4rem;
            display: flex;
            justify-content: center;
        }
    }

    &__errors {
        margin-top: 2rem;

        .error {
            @include error-text;
            text-align: center;
        }
    }

    .button {
        @include landing-button-1;

        &--disabled {
            @include landing-button-disabled-1;
        }
    }

    &__buttons {
        // margin-top: 1rem;
        margin-top: 2rem;
        display: flex;
        justify-content: center;
    }
}
