.billing {
    height: 100%;
    position: relative;

    .page {
        @include set-pos-ltwh(absolute, 0, 0, 100%, 100%);

        .header {
            margin: 4rem 0;
        }

        .statusText {
            @include fsr-18;
            padding: 0 4rem;
        }

        .catalogItems {
            display: flex;
            flex-direction: column;
            align-items: center;

            .catalogItem {
                margin-bottom: 4rem;
                width: 75%;
                padding: 4rem 0;
                border-radius: 1rem;
                border: solid 1px $landing-color-primary-dim-2;

                cursor: pointer;

                &__title {
                    @include fsr-24;
                }

                &__subtitle {
                    @include fsr-14;
                    color: $landing-color-primary-dim-2;
                }

                &__price {
                    margin-top: 2rem;
                    @include fsr-20;
                    font-style: italic;
                    color: $landing-color-secondary;
                }
            }
        }

        .btn {
            display: inline-block;
            margin-top: 2rem;
            padding: 1rem 2rem;
            border-radius: 1rem;
            border: solid 1px #555;

            user-select: none;
            cursor: pointer;
        }

        &--choose {
            // .header {
            //     margin-bottom: 2rem;
            // }

            .status {
                margin-bottom: 4rem;
                color: $landing-color-primary-dim-3;
            }
        }

        // &--payment {
        //     // background-color: red;
        // }

        // &--status {
        //     // background-color: blue;
        // }
    }
}
