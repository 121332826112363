.editItem {
    padding: 0 1rem 0 3rem;
    // display: grid;
    // grid-template-columns: 30% 50% 20%;
    display: flex;
    align-items: center;

    &--hideLabel {
        flex-grow: 1;
        grid-template-columns: 100%;
    }

    &__label {
        flex: 3;
        // text-align: right;
        // margin-right: 2rem;
        padding-right: 1rem;
        font-weight: bold;
    }

    &__element {
        flex: 5;
    }

    &__buttons {
        flex: 2;
        text-align: right;
    }

    input {
        width: 100%;
        padding: 0.5rem 1rem;
        outline: none;
        border-radius: 1rem;
        border: solid 1px $color-primary-1;
        color: $color-primary-1;
    }

    textarea {
        width: 100%;
        height: 10rem;
        padding: 0.5rem 1rem;
        resize: none;
        font-family: inherit;
        outline: none;
        border-radius: 1rem;
        border: solid 1px $color-primary-1;
        color: $color-primary-1;
    }

    select {
        height: 3rem;
        padding: 0.5rem;
        outline: none;
        border-radius: 0.8rem;
        border: solid 1px $color-primary-1;
        color: $color-primary-1;
    }

    .switch {
        .label {
            color: $color-primary-1;
        }

        &__button {
            border: solid 1px $color-primary-1;

            .thumb {
                background-color: rgba($color-primary-1, 0.7);
            }
        }
    }

    &__image {
        &.invalid {
            border: solid 5px blue;
        }
    }

    &__dropZone {
        &.dragActive {
            border: solid 5px black;
        }
    }

    .invalid {
        background-color: red;
        color: white;
    }

    &--url {
        .editItem__label {
            grid-area: 1 / 1 / 2 / 3;
        }

        .editItem__element {
            grid-area: 2 / 1 / 3 / 3;
        }
    }

    &__debugText {
        font-size: 1rem;
    }
}
