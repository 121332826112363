.dropdownMenu {
    // background-color: yellow;
    position: relative;
    display: flex;
    flex-direction: column;
    // overflow: hidden;

    &__trigger {
        cursor: pointer;

        .avatar {
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            background: #444;
        }
    }

    &__contents {
        position: absolute;
        right: 0;
        top: 4rem;
        // width: 20rem;
        // height: 20rem;
        // padding: 0 2rem 0 6rem;
        // border-radius: 0 0 3rem 3rem;
        border-radius: 0.5rem;
        background-color: #333;
        // border: solid 1px #555;
        // background-color: #333;

        display: flex;
        flex-direction: column;
        align-items: flex-end;
        overflow: hidden;
        transition: all 0.1s;

        &--hide {
            opacity: 0;
            visibility: hidden;
        }

        .menuItem {
            align-self: stretch;
            padding: 1.5rem 2rem 1.5rem 6rem;
            white-space: nowrap;
            text-align: right;
            user-select: none;

            &.lineAbove {
                margin-top: 1rem;
                // padding-top: 1.5rem;
                border-top: solid 1px #888;
            }

            &:hover {
                background-color: #555;
                cursor: pointer;
            }
        }
    }
}
