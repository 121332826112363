.fancyButton {
    padding: 1rem 4rem;
    border-radius: 1rem;
    border: none;
    outline: none;
    background-color: $color-feature-1;
    color: black;

    cursor: pointer;
    transition: opacity 0.3s;

    &.disabled {
        opacity: 0.3;
        cursor: default;
    }

    &.hide {
        opacity: 0;
        cursor: default;
    }
}
