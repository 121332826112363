.editSection {
    &:not(:first-child) {
        margin-top: 1rem;
    }

    &--debug {
        border: dashed 4px lightblue;
    }
    // background: yellow;

    &__header {
        // margin-bottom: 1rem;
        display: flex;
        align-items: center;
        padding: 0.8rem 1rem;
        // background-color: rgba($color-primary-1, 0.6);
        background-color: rgba($color-accent-1, 0.3);
        // border-top: solid 1px rgba($color-primary-1, 0.3);
        // border-bottom: solid 1px rgba($color-primary-1, 0.3);
        // color: white;
        font-weight: bold;
        // color: $color-primary-1;

        .triangle {
            margin-right: 0.5rem;
            width: 1rem;
            height: 1rem;

            svg {
                width: 100%;
                height: 100%;

                // filter: drop-shadow(1px 1px 0px $color-primary-1);

                path {
                    // fill: white;
                    fill: $color-primary-1;
                }
            }
            // width: 0;
            // height: 0;
            // border-top: 0.6rem solid transparent;
            // border-left: 0.9rem solid red;
            // border-bottom: 0.6rem solid transparent;
            cursor: pointer;
            user-select: none;
            transform: translateX(0) translateY(-0.4rem);
            transition: transform 0.2s;

            &--open {
                transform: translateX(0.4rem) translateY(0) rotate(90deg);
            }
        }

        .label {
            font-size: 1.6rem;
        }
    }

    &__contents {
        height: 0;
        overflow: hidden;
        opacity: 0;
        transition: opacity 0.5s;

        &--open {
            height: auto;
            opacity: 1;
        }
    }
}
