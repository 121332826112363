.rtDisplay {
    // background-color: yellow;

    .title {
        // font-weight: bold;
        text-align: center;
        font-size: 2rem;
        margin-bottom: 3rem;
    }

    .groupHeader {
        display: flex;
        text-decoration: underline;

        .triangle {
            margin-right: 0.5rem;
            width: 1.5rem;
            height: 1.5rem;

            svg {
                width: 100%;
                height: 100%;

                path {
                    // fill: $color-primary-1;
                    fill: $color-primary-trn-2;
                }
            }
            cursor: pointer;
            user-select: none;
            // transform: translateX(0) translateY(-0.4rem);
            transition: transform 0.2s;

            &--open {
                // transform: translateX(0.4rem) translateY(0) rotate(90deg);
                transform: rotate(90deg);
            }
        }
    }

    .dataBlock {
        display: grid;
        @include fsr-10;
    }

    .row {
        display: flex;
    }

    .column {
        padding-right: 2rem;
    }
}
