.landingSite {
    @include fixed-full-screen;

    background-color: black;
    // color: yellow;
    color: $landing-color-primary;

    transition: transform 1s;
    // transform: translateY(-90%);

    &.showing-enter {
        transform: translateY(-100%);
    }

    &.showing-enter-active,
    &.showing-enter-done {
        transform: translateY(0);
    }

    &.showing-exit-active,
    &.showing-exit-done {
        transform: translateY(-100%);
    }
}
