.mainFrame {
    position: relative;
    width: 100%;
    height: 100%;
    // color: yellow;

    &__bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    &__contents {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}
