.phoneSim {
    padding: 0.5rem;
    width: 100%;
    height: 100%;
    // background-color: $panel-bg-color-1;
    // background-color: black;

    border-radius: 2rem;
    // border: solid 3px black;
    border: solid 2px $landing-color-primary-dim-2;

    @include for-phone-portrait {
        padding: 0;
        border-radius: 0;
        border: none;
    }
}
