.surveySummary {
    // background-color: yellow;

    &__heading {
        text-align: center;
        font-size: 2.4rem;
        font-weight: bold;
    }

    &__surveyPage {
        cursor: pointer;
        // margin-top: 2rem;
        padding-bottom: 1rem;
        // padding: 1rem;
        border-radius: 1rem;
        background: $app-color-bg;
        border: solid 1px $landing-color-secondary;
        overflow: hidden;

        &:not(:first-child) {
            margin-top: 4rem;
        }

        &:last-child {
            margin-bottom: 4rem;
        }

        &Heading {
            padding: 1rem;
            margin-bottom: 1rem;
            text-align: center;
            @include fsr-14;
            font-weight: 500;
            background-color: $landing-color-secondary-dim;
        }
    }

    &__surveyItem {
        padding: 0 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        // padding: 0.5rem;
        // border-bottom: solid 1px rgba($color-primary-1, 0.3);

        // &:first-child {
        //     border-top: solid 1px rgba($color-primary-1, 0.3);
        // }

        // &:not(:first-child) {
        // margin-top: 1rem;
        // }

        // &:nth-child(odd) {
        //     background-color: #d8d8d8;
        // }

        // &:nth-child(even) {
        //     background-color: #e8e8e8;
        // }

        .displayName {
            // margin-bottom: 0.5rem;
            margin-right: 1rem;
            font-size: 1.4rem;
            font-weight: 500;
        }

        .valBlock {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            @include fsr-14;

            &__valOption {
                // &:not(:last-child) {
                margin-bottom: 1rem;
                // }

                padding: 0.4rem 1rem;
                border-radius: 1rem;
                border: solid 1px $landing-color-primary-dim-3;

                // &:not(:last-child) {
                //     margin-right: 1rem;
                // }
                &:not(:first-child) {
                    margin-left: 1rem;
                }
            }

            &__valText {
                text-decoration: underline;
            }
        }
    }

    &--debug {
        opacity: 0.5;
    }
}
