.catalog {
    // background-color: lightblue;
    display: grid;
    column-gap: 2rem;

    .title {
        grid-column: 1 / -1;
        // }

        // .header {
        text-align: center;
        padding: 1rem 0;
        font-size: 1.8rem;
    }

    // .row {
    .colHeader {
        font-weight: bold;
    }

    // display: flex;
    // padding: 0.25rem 2rem;
    // font-size: 1.4rem;

    // &:nth-child(odd) {
    // &:nth-child(even) {
    // }

    // &:nth-child(even) {
    // }

    .item {
        font-size: 1.4rem;

        // flex: 1;
        &.bgColor--even {
            background-color: #d8d8d8;
        }

        &.bgColor--odd {
            background-color: #e8e8e8;
        }

        &.newRow {
            grid-column-start: 1;
        }
    }

    // .item:nth-child(1) {
    //     flex: 3;
    // }

    // .item:nth-child(2) {
    //     flex: 1;
    // }
    // }
}
