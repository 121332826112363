.heightPicker {
    // background: blue;
    // color: white;

    &__selects {
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 1.6rem;

        select {
            margin-right: 1rem;

            outline: none;
            padding: 1rem;
            border-radius: 1rem;
            border: solid 1px $landing-color-primary-dim-3;
            // font-size: 1.4rem;
            background-color: black;
            color: $landing-color-primary;

            &:last-of-type {
                margin-left: 2rem;
            }
        }

        .label {
            color: $landing-color-primary-dim-2;
        }
    }

    &__debug {
        font-size: 1.2rem;
    }
}
