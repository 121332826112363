.adminScreen {
    @include fixed-full-screen;

    background-size: cover;
    background-position: left top;

    background-image: url(https://storage.googleapis.com/gratia-media/images/bg/bg-admin2-3008x2001.jpg);

    @media only screen and (max-width: $bp-large) {
        background-image: url(https://storage.googleapis.com/gratia-media/images/bg/bg-admin2-1601x1066.jpg);
    }

    @media only screen and (max-width: $bp-medium) {
        background-image: url(https://storage.googleapis.com/gratia-media/images/bg/bg-admin2-1202x798.jpg);
    }

    padding: $nav-height 4rem 4rem 4rem;
    display: flex;

    &__leftNav {
        margin-right: 4rem;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    &__mainFrame {
        flex: 1;
    }
}
