.imagePreloader {
    position: fixed;
    left: 0;
    top: 0;
    width: 1px;
    height: 1px;
    background-color: red;
    visibility: hidden;

    img {
        width: 100%;
        height: 100%;
    }
}
