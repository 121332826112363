.editor {
    height: 100%;
    font-size: 1.4rem;
    color: $color-primary-1;
    // color: yellow;
    display: flex;
    flex-direction: column;

    &__header {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .label {
            // flex-grow: 1;
            display: flex;
            align-items: center;
            font-size: 2rem;

            .spinner {
                margin-left: 2rem;
                background: linear-gradient(
                    to right,
                    $color-primary-1 5%,
                    transparent 25%
                );
            }
        }

        .buttons {
            display: flex;
            align-items: center;

            .spinner {
                margin-right: 1rem;
            }

            .button {
                // display: inline-block;
                opacity: $opacity-deselected-1;
                cursor: pointer;

                svg {
                    width: 1.5rem;
                    height: 1.5rem;

                    // filter: drop-shadow(1px 1px 0px $color-primary-1);

                    path {
                        fill: $color-primary-1;
                    }
                }

                &--autoscroll {
                    margin-right: 1rem;
                    display: flex;
                    align-items: center;

                    .label {
                        font-size: 1rem;
                        font-weight: bold;
                    }

                    .icon {
                        svg {
                            width: 2rem;
                            height: 2rem;
                            transform: rotate(180deg);
                            // background-color: yellow;
                        }
                    }
                }

                &--save {
                    margin-left: 0.5rem;
                }

                &--enabled {
                    opacity: 1;
                }
            }
        }
    }

    &__contents {
        // flex: 1;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        transition: opacity 0.5s;

        &.noData {
            opacity: 0.5;
        }

        .multiSelect,
        .radio,
        .funcButton {
            display: inline-block;

            &__val {
                display: inline-block;
                padding: 0.5rem 1rem;
                margin: 0.4rem;
                // background-color: lightblue;
                border-radius: 0.8rem;
                background: #ccc;
                color: $color-primary-1;
                user-select: none;
                cursor: pointer;

                &.sel {
                    // background-color: green;
                    // color: white;
                    background: $color-accent-1 !important;
                    color: $color-accent-2 !important;
                }

                &.disabled {
                    opacity: 0.5;
                    cursor: default;
                }
            }
        }
    }

    &__noneLoaded {
        height: 100%;
        padding: 0 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        opacity: 0.3;
        font-size: 3rem;

        .arrow {
            font-size: 4rem;
        }
    }
}
