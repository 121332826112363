.smsOptInDescScreen {
    // background-color: blue;

    .contents {
        margin: 0 15%;
        padding: 3rem 0;
        color: $landing-color-primary-dim-2;

        .title {
            // margin-bottom: 1rem;
            text-align: center;
            font-size: 3rem;
            font-weight: bold;
            color: $landing-color-primary;
        }

        a {
            color: $landing-color-primary-dim;
        }

        p,
        ul {
            margin-bottom: 1rem;
        }

        // h1 {
        //     color: $landing-color-primary;
        // }

        h2 {
            margin-bottom: 3rem;
            text-align: center;
            color: $landing-color-primary-dim;
        }

        .imgBlock {
            margin: 3rem 0;
            text-align: center;
        }

        img {
            margin: 0 1rem;
            width: 45%;
        }
    }
}
