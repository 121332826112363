.debug {
    position: fixed;
    z-index: 1000;
    left: 0;
    width: 100%;
    bottom: 1rem;
    display: flex;
    justify-content: center;
    user-select: none;

    &__container {
        margin: 0 1rem;
        border-radius: 1.5rem;
        font-family: $font-sans-1;
        font-size: 1.3rem;
        padding: 1rem 3rem;
        line-height: 150%;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.4);

        @include for-phone-portrait {
            font-size: 1rem;
        }
    }

    &__item {
        color: #ccc;

        &:not(:last-child) {
            margin-right: 3rem;
        }
    }

    &__trigger {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 3rem;
        height: 3rem;
        cursor: pointer;
        // background-color: yellow;
    }
}
