.alert {
    @include fixed-full-screen;

    display: flex;
    justify-content: center;
    align-items: center;

    // background-color: yellow;

    &__dimmer {
        @include fixed-full-screen;

        background-color: rgba(black, 0.3);
    }

    &__contentsContainer {
        @include fixed-full-screen;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .contents {
            max-width: 50vw;
            max-height: 90vh;
            padding: 5rem;
            border-radius: 15px;
            // text-align: center;
            // font-size: 2rem;

            display: flex;
            flex-direction: column;
            align-items: center;

            overflow: auto;

            // background-color: red;
            // color: white;

            @include for-phone-portrait {
                max-width: 80vw;
                font-size: 1.4rem;
            }

            &.info {
                background-color: white;
                color: black;
            }

            &.warning {
                background-color: yellow;
                color: black;
            }

            &.error {
                background-color: red;
                color: white;
            }

            // .message {
            // }

            .closeBtn {
                margin-top: 2rem;
                font-size: 1.6rem;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}
