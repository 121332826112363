.actionBar {
    display: flex;
    align-items: center;

    .title {
        font-size: 1.6rem;
        text-transform: uppercase;
        font-weight: bold;
        color: $color-primary-1;
    }
}
