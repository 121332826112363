.chooser {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 20vw;
    font-size: 1.4rem;
    color: $color-primary-1;

    &__controls {
        padding: 1rem;
        // display: grid;
        // grid-template-columns: 1fr 2rem;
        // row-gap: 0.5rem;
        background-color: #ddd;

        .filterInput {
            flex: 1;
            padding-right: 1rem;
            // background-color: yellow;

            input {
                width: 100%;
                height: 3rem;
                padding: 0 1rem;
                outline: none;
                border-radius: 0.8rem;
                border: solid 1px $color-primary-1;

                &::placeholder {
                    color: #bbb;
                }
            }
        }

        .spinner {
            margin-left: 0.5rem;
        }

        .newBtn,
        .duplicateBtn {
            width: 2.2rem;
            // height: 1.5rem;
            cursor: pointer;

            svg {
                width: 100%;
                height: 100%;

                // filter: drop-shadow(1px 1px 0px $color-primary-1);

                path {
                    fill: $color-primary-1;
                }
            }
        }

        .duplicateBtn {
            margin-left: .3rem;
        }

        .sort {
            grid-area: 2 / 1 / 2 / 3;
            // background-color: red;
            display: flex;
            align-items: center;

            select {
                height: 3rem;
                padding: 0.5rem;
                outline: none;
                border-radius: 0.8rem;
                border: solid 1px $color-primary-1;
                color: $color-primary-1;
            }

            &__direction {
                margin-left: 1rem;
                width: 2rem;
                user-select: none;
                // height: 1.5rem;
                // background-color: lightblue;

                svg {
                    width: 100%;
                    height: 100%;

                    // filter: drop-shadow(1px 1px 0px $color-primary-1);

                    path {
                        fill: $color-primary-1;
                    }
                }
            }

            .status {
                margin-left: 2rem;
            }
        }

        .toggleGroups {
            padding-top: 0.5rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .toggleGroup {
                display: flex;

                .toggles {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 0.5rem;

                    .label {
                        font-size: 1.2rem;
                        font-weight: bold;
                    }

                    .toggle {
                        padding: 0.2rem 0.6rem;
                        border-radius: 1rem;
                        border: solid 1px rgba($color-accent-1, 0.3);
                        font-size: 1.2rem;
                        white-space: nowrap;
                        // opacity: $opacity-deselected-1;
                        user-select: none;
                        cursor: pointer;

                        &--enabled {
                            background-color: rgba($color-accent-1, 0.3);
                            // color: white;
                        }

                        &--disabled {
                            display: none;
                            // opacity: 0.5;
                            // cursor: default;
                        }
                    }
                }
            }
        }
    }

    &__contents {
        // margin-top: 1rem;
        // padding-top: 2rem;
        flex-grow: 1;
        border-top: solid 1px #bbb;
        border-bottom: solid 1px #bbb;
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        .item {
            display: flex;
            // margin: 1rem 0;
            padding: 1rem;
            // justify-content: space-between;
            // border: solid 1px transparent;
            align-items: center;
            cursor: pointer;

            // &:not(:last-child) {
            border-bottom: solid 1px #bbb;
            // border-top: solid 1px #bbb;
            // padding: 0.5rem 0;
            // margin-bottom: 0.5rem;
            // }

            // &:nth-child(odd) {
            //     background-color: #d8d8d8;
            // }

            // &:nth-child(odd) {
            //     background-color: #d8d8d8;
            // }

            // &:nth-child(even) {
            //     background-color: #e8e8e8;
            // }

            .itemSummary {
                &__el {
                    // &--0 {
                    //     font-weight: bold;
                    // }

                    &--1 {
                        margin-top: 0.2rem;
                        font-size: 1.3rem;
                        opacity: 0.6;
                    }
                }
            }

            &__selectBox {
                margin-right: 1rem;
                width: 1.2rem;
                height: 1.2rem;
                border-radius: 0.2rem;
                border: solid 1px $color-primary-1;
                background-color: #ddd;

                &--sel {
                    // background-color: $color-accent-1;
                    background-color: #b09f8a;
                }
            }

            &__details {
                flex-grow: 1;
                padding-right: 1rem;
                // background: blue;
            }

            &__deleteBtn {
                flex: 0 0 1.5rem;
                width: 1.5rem;
                height: 1.5rem;
                // margin-left: 1rem;
                visibility: hidden;

                svg {
                    width: 100%;
                    height: 100%;

                    // filter: drop-shadow(1px 1px 0px $color-primary-1);

                    path {
                        fill: $color-primary-1;
                    }
                }
            }

            &:hover {
                // background-color: #eee;
                // border-radius: 1rem;
                // border: solid 1px #aaa;
                // background-color: #d0d0d0;
                background-color: rgba($color-accent-1, 0.1);

                .item__deleteBtn {
                    visibility: visible;
                }
            }

            &--sel {
                border-radius: 1rem;
                // background-color: #ccc !important;
                background: $color-accent-1 !important;
                color: $color-accent-2 !important;

                .item__deleteBtn {
                    svg {
                        path {
                            fill: $color-accent-2;
                        }
                    }
                }
            }
        }
    }

    &__footer {
        padding: 1rem;
        text-align: center;
        background-color: #ddd;
    }
}
