.image {
    display: inline-block;
    position: relative;
    width: 15rem;
    height: 15rem;

    &__imgContainer {
        width: 100%;
        height: 100%;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: contain;
    }

    &.debug {
        border: solid 5px blue;
    }

    &.fullScreen {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background-color: #222;
        cursor: zoom-out;
    }

    &__fullScreenBtn {
        position: absolute;
        right: 0.3rem;
        top: 0.3rem;
        width: 2rem;
        height: 2rem;
        opacity: 0.5;
        cursor: zoom-in;

        svg {
            // filter: drop-shadow(1px 1px 1px $landing-color-text-shadow1);
            filter: drop-shadow(1px 1px 1px #000);

            path {
                fill: #fff;
            }
        }

        &.debug {
            background-color: #aaa;
        }
    }
}
