.app {
    position: relative;
    // background-color: blue;
    background-color: black;

    &__fallback {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
}
