.editItemButtons {
    // text-align: right;
    // background-color: yellow;
    // white-space: nowrap;
    display: inline-flex;
    justify-content: flex-end;

    &.arrayControls {
        padding: 1rem 2rem;
        border-radius: 1rem;
        background-color: #ccc;
    }

    .button {
        display: inline-block;
        opacity: 0.4;

        width: 1.5rem;
        height: 1.5rem;
        // margin-left: 1rem;
        // display: none;
        user-select: none;

        &:not(:first-child) {
            margin-left: 0.5rem;
        }

        svg {
            width: 100%;
            height: 100%;

            // filter: drop-shadow(1px 1px 0px $color-primary-1);

            // path {
            //     fill: rgba($color-primary-1, 0.8);
            // }
        }

        &--add {
            width: 1.6rem;
            height: 1.6rem;
        }

        &.enabled {
            opacity: 1;
            cursor: pointer;
        }
    }

    // &--changed {
    //     .button {
    //         opacity: 1;
    //     }
    // }

    &--new {
        .button--undo,
        .button--save {
            display: none;
        }
    }

    // &--invalid {
    //     .button--save {
    //         opacity: 0.4;
    //     }
    // }

    &__debugText {
        font-size: 1rem;
    }
}
