.pageRouter {
    .bgMorph,
    .bigScroll {
        &.showing-enter {
            transform: translateY(-100vh);
        }

        &.showing-enter-active {
            transform: translateY(0);
            transition: transform 1000ms;
        }

        &.showing-enter-done {
            transform: none;
        }

        &.showing-exit {
            transform: translateY(0);
        }

        &.showing-exit-active {
            transform: translateY(-100vh);
            transition: transform 1000ms;
        }

        &.showing-exit-done {
            transform: translateY(-100vh);
        }
    }
}
