.actionItem {
    position: relative;
    display: inline-block;
    margin-left: 1vw;
    padding: 0.5rem 2rem;
    border-radius: 0.8rem;
    // background-color: rgba(blue, 0.2);
    // background: $color-primary-trn-2;
    // background: rgba($color-accent-1, .5);
    background: #ccc;
    color: $color-primary-1;
    // color: white;
    font-size: 1.4rem;
    cursor: pointer;

    &.sel {
        // background-color: rgba(red, 0.5);
        background: $color-accent-1;
        color: $color-accent-2;
    }

    &.changed::after {
        position: absolute;
        right: 0.3rem;
        top: 0.3rem;
        content: '';
        width: .7rem;
        height: .7rem;
        border-radius: 50%;
        // background-color: #b5a48e;
        // background-color: #84b4ee;
        // background-color: #caaf81;
        background: rgba($color-accent-2, 0.7);
    }
}
