.topBar {
    // height: 100%;
    // padding: 1.8rem 1rem 1.8rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 1.4rem;
    color: $color-primary-1;

    // &__left {
    //     align-self: flex-end;
    //     // align-self: center;

    //     &--title {
    //         font-size: 1vw;
    //         letter-spacing: 0.2vw;
    //         transform: translateX(-3.5vw) translateY(-.2rem);
    //     }
    // }

    &__left {
        width: 33%;
    }

    &__center {
        width: 34%;
        text-align: center;
        font-size: 2rem;
    }

    &__right {
        width: 33%;
        // align-self: center;

        display: flex;
        justify-content: flex-end;
        align-items: center;

        &--search {
            input {
                padding: 0.6rem 1rem;
                outline: none;
                border: solid 1px $input-border-color-1;
                border-radius: 0.8rem;
                background-color: rgba(white, 0.8);
                color: inherit;
            }
        }

        &--userInfo {
            margin-left: 2rem;
        }
    }
}
