.forcePortrait {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vh;
    height: 100vw;
    padding: 1rem;
    transform: rotate(-90deg);
    transform-origin: left top;
    top: 100vh;
    background: no-repeat center/cover
        url(https://storage.googleapis.com/gratia-media/web/images/bg-texture-1-1440x1024.jpg)
        $app-color-bg;
    // background-image: url(https://storage.googleapis.com/gratia-media/images/bg/exp/iStock-1070065352-2.jpg);
    // background-position: center;
    // background-size: cover;

    &__darken {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.3;
    }

    &__contents {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .text {
            font-size: 7vh;
            // background: -webkit-linear-gradient(yellow, blue);
            // background: -webkit-linear-gradient(#31fbc6, #00e25a);
            // background-clip: text;
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
            // color: $landing-color-primary;
            color: $landing-color-secondary;

            &--reversed {
                transform: rotate(180deg);
            }
        }

        .icon {
            margin: 6rem 0;
            width: 5rem;
            height: 5rem;

            path {
                // fill: #3797bd;
                // fill: #9bcbdf;
                // fill: $landing-color-secondary;
                fill: $landing-color-primary;
            }

            // transform: scale(1);
            animation: spin 2s infinite;
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0);
    }

    25% {
        transform: rotate(0);
    }

    50% {
        transform: rotate(-180deg);
    }

    75% {
        transform: rotate(-180deg);
    }

    100% {
        transform: rotate(-360deg);
    }
}
