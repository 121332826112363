.leftNav {
    // height: 100%;
    // font-size: 1.6rem;
    display: flex;
    flex-direction: column;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    &__links {
        display: inline-block;
        margin-top: 2rem;
        // margin: 0 4rem;
        // padding: 1rem;
        // width: 20rem;
        // border-radius: 1rem;
        // border: solid 1px $section-border-color-1;
        // background-color: $section-bg-color-1;
        // background: rgba(0, 0, 0, 0.2);

        .link {
            position: relative;
            display: flex;
            align-items: center;
            margin: 0.8rem 0;
            padding: 0.5rem 2rem 0.5rem 1.2rem;
            // width: 100%;
            // height: 10rem;

            // outline: none;
            // border: solid 1px $button-border-color-1;
            border-radius: 1rem;
            // background: $color-transparent-white-mid;
            // background: #faf9f6;
            // background: $color-transparent-white-light-2;
            // background: rgba($color-primary-1, 0.4);
            // background: rgba($color-primary-1, 0.8);
            background: $color-primary-trn-2;
            // background: $color-primary-1;
            // opacity: 0.5;
            user-select: none;
            cursor: pointer;

            white-space: nowrap;
            // font-size: inherit;
            // text-transform: uppercase;
            // font-weight: bold;
            // color: $color-primary-1;
            // transition: all 0.2s;

            &:first-child {
                margin-top: 0;
            }

            // &:hover {
            //     // background: $color-transparent-white-light-1;
            //     opacity: 0.6;
            // }

            &--sel {
                // background: $color-transparent-white-light-2;
                // background: #faf9f6;
                // opacity: 1;
                // background: rgba($color-primary-1, .7);
                // background: #56779e;
                background: $color-accent-1;
            }

            &__icon {
                width: 2.5rem;
                height: 2.5rem;
                // background-color: lightblue;

                svg {
                    width: 100%;
                    height: 100%;

                    // filter: drop-shadow(1px 1px 0px $color-primary-1);

                    path {
                        // fill: $color-primary-1;
                        // fill: $color-accent-2;
                        fill: #ccc;
                    }
                }

                // &--reports {
                //     background: no-repeat center/contain
                //         url(../../../../assets/images/icons/home.svg) yellow;
                // }

                &--sel {
                    svg {
                        path {
                            fill: $color-accent-2;
                        }
                    }
                }
            }

            &__text {
                position: relative;
                margin-top: -0.3rem;
                margin-left: 1rem;
                font-size: 1.6rem;
                // color: $color-accent-2;
                color: #ccc;
                // filter: drop-shadow(1px 1px 0px $color-primary-1);

                &--sel {
                    color: $color-accent-2;
                }
            }

            &.changed::after {
                position: absolute;
                right: 0.5rem;
                top: 0.5rem;
                content: '';
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                // background-color: #b5a48e;
                // background-color: #84b4ee;
                // background-color: #caaf81;
                background: rgba($color-accent-2, 0.7);
            }
        }

        .spacer {
            height: 2rem;
        }
    }

    &__switch {
        margin-top: 2rem;
    }
}
