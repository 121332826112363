.sideNav {
    &__dimmer {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: black;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s, visibility 0.3s;

        &--showing {
            visibility: visible;
            opacity: 0.6;
        }
    }

    &__links {
        position: fixed;
        left: 0;
        top: 4.8rem;
        padding: 0 20px;
        background-color: rgba(black, 0.6);
        border-radius: 0 2rem 2rem 0;

        transform: translateX(-100%);
        transition: transform 0.3s;

        &--showing {
            transform: translateX(0);
        }

        .link {
            // margin: 0.5rem 0;
            padding: 0.5rem;
            font-size: 1.6rem;
            // background-color: lightblue;
            color: $color-bigScroll-fg-1;
            cursor: pointer;

            &__text {
                display: inline-block;
                padding: 1rem;

                &.sel {
                    border-radius: 1rem;
                    border: solid 2px $landing-color-secondary;
                }
            }
        }

        .adminSwitch {
            margin: 1rem 0 3rem 0;
            padding-top: 2rem;
            border-top: solid 2px $landing-color-primary-dim-3;
        }
    }

    &__hamburger {
        position: fixed;
        left: -4px;
        top: -4px;
        width: 4.8rem;
        height: 4.8rem;
        border-radius: 0 0 1rem 0;
        background-color: rgba(black, 0.05);
    }
}
