.page {
    height: 100%;

    &--home {
        // display: flex;
        // align-items: flex-start;
        // display: grid;
        // grid-template-columns: repeat(3, 1fr);
        // border: solid 5px red;

        .panel {
            width: 100%;
            // height: 75%;
            height: 90%;
        }
    }
}
