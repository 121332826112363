.flipCard {
    position: relative;
    perspective: 75rem;
    -moz-perspective: 75rem;

    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;

    &__side {
        grid-area: 1 / 1 / 2 / 2;

        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transition: all 0.8s ease;

        &--back {
            transform: rotateY(180deg);
        }
    }

    &.flipped {
        .flipCard__side--front {
            transform: rotateY(-180deg);
        }

        .flipCard__side--back {
            transform: rotateY(0deg);
        }
    }
}
