// ----- Breakpoints -----

$bp-large: 100em; // 1600px
$bp-medium: 75em; // 1200px
$bp-small: 37.5em; // 600px

// ----- Fonts -----

// $landing-font-serif-1: 'Times New Roman', Times, serif;
// $landing-font-sans-1: 'Lato', sans-serif;

// $landing-font-logo-sub: 'EB Garamond', serif;

// // $font-serif-1: 'Times New Roman', Times, serif;
// $font-sans-1: 'Lato', sans-serif;
$font-sans-1: 'Poppins', sans-serif;

// Albertan Pro - Light: 300, Book: 400, Bold: 700
@mixin font-serif-1 {
    font-family: albertan-pro, sans-serif;
    font-weight: 300;
    font-style: normal;
}

// $font-size-normal: 1.6rem;
// $fsr-14: clamp(1rem, 1.95vmin, 1.4rem);
// $fsr-14: clamp(1rem, 1.6vmin, 1.4rem);
// $fsr-15: clamp(1rem, 1.8vmin, 1.5rem);
// $fsr-16: clamp(1rem, 2.22vmin, 1.6rem);
// $fsr-18: clamp(1rem, 2.5vmin, 1.8rem); //1.8rem;
// $fsr-20: clamp(1rem, 2.8vmin, 2rem);
// $fsr-34: clamp(2rem, 4.72vmin, 3.4rem);
// $fsr-35: clamp(2rem, 4.9vmin, 3.5rem);
// $fsr-170: clamp(5rem, 23.6vmin, 17rem);

@mixin fsr-10 {
    font-size: clamp(1rem, 1.2vmin, 1rem);

    @include for-phone-portrait {
        font-size: 1rem;
    }
}

@mixin fsr-14 {
    font-size: clamp(1rem, 1.6vmin, 1.4rem);

    @include for-phone-portrait {
        font-size: 1.4rem;
    }
}

@mixin fsr-15 {
    font-size: clamp(1rem, 1.8vmin, 1.5rem);

    @include for-phone-portrait {
        font-size: 1.5rem;
    }
}

@mixin fsr-16 {
    font-size: clamp(1rem, 2.22vmin, 1.6rem);

    @include for-phone-portrait {
        font-size: 1.6rem;
    }
}

@mixin fsr-18 {
    font-size: clamp(1rem, 2.5vmin, 1.8rem); //1.8rem;

    @include for-phone-portrait {
        font-size: 1.8rem;
    }
}

@mixin fsr-20 {
    font-size: clamp(1rem, 2.8vmin, 2rem);

    @include for-phone-portrait {
        font-size: 2rem;
    }
}

@mixin fsr-24 {
    font-size: clamp(1rem, 3vmin, 2.4rem);

    @include for-phone-portrait {
        font-size: 2.4rem;
    }
}

@mixin fsr-34 {
    font-size: clamp(2rem, 4.72vmin, 3.4rem);

    @include for-phone-portrait {
        font-size: 3.4rem;
    }
}

@mixin fsr-35 {
    font-size: clamp(2rem, 4.9vmin, 3.5rem);

    @include for-phone-portrait {
        font-size: 3.5rem;
    }
}

@mixin fsr-170 {
    font-size: clamp(5rem, 23.6vmin, 17rem);

    @include for-phone-portrait {
        font-size: 17rem;
    }
}

// ----- Colors -----

// $landing-color-primary: #35c0e1;
// $landing-color-primary-light1: #5dcde7;
$landing-color-primary-light2: #86d9ed;
// $landing-color-primary-light3: #aee6f3;
// $landing-color-primary-light4: #d7f2f9;
// $landing-color-primary-dark1: #2a9ab4;
// $landing-color-primary-dark2: #207387;
$landing-color-primary-dark3: #154d5a;
// $landing-color-primary-dark4: #0b262d;

// $landing-color-secondary: #cbd753;

// $landing-color-tertiary: #f59235;

$landing-color-primary: #dddddd;
$landing-color-primary-highlight: #ffffff;
$landing-color-primary-dim: #bbb;
$landing-color-primary-dim-2: #999;
$landing-color-primary-dim-3: #777;
$landing-color-secondary: #998675;
$landing-color-secondary-dim: #695a4d;

$landing-color-error: red;

$app-color-bg: #222;
$app-color-bg-dim: #111;

$landing-color-grey-light1: #999;
$landing-color-grey-mid1: #555;

$landing-color-text-shadow1: #333;

$color-feature-1: rgba(white, 0.6);
$color-feature-2: #febe81;

$color-error-1: #febe81;

$color-grey-mid-1: #555;
$color-grey-mid-2: #777;

$color-bigScroll-fg-1: #fff;
$color-bigScroll-fg-2: #ddd;
$color-bigScroll-shadow-1: #333;

// $color-text-shadow1: #333;

// $color-primary-1:#033551;
$color-primary-1: #535157;
$color-primary-trn-1: rgba($color-primary-1, 0.2);
$color-primary-trn-2: rgba($color-primary-1, 0.4);
// $color-primary-2:#033551; //black;
$color-primary-light-1: rgba(#033551, 0.2);

$color-accent-1: #49729e; // from bg water

$color-accent-2: #f8f5f2; // from bg sky

$color-transparent-white-mid: rgba(white, 0.4);
$color-transparent-white-light-1: rgba(white, 0.6);
$color-transparent-white-light-2: rgba(white, 0.9);

// $section-bg-color-1: rgba(#727f95, .3);//rgba(#4b7198, .4);//rgba(#a3bcd2, 0.4);
$panel-bg-color-1: rgba(white, 0.8);

// $section-border-color-1: $color-primary-light-1;
$panel-border-color-1: $color-primary-light-1;
$button-border-color-1: $color-primary-light-1;
$input-border-color-1: $color-primary-light-1;

$opacity-deselected-1: 0.4;

// @mixin color-error-1 {
//     background-color: #685087;
//     // background-color: #7a89c4;
//     color: $color-feature-2;
// }

@mixin color-error-1 {
    background-color: $color-grey-mid-1;
    color: white;
}

// ----- Borders -----

$landing-border-radius1: 0.7rem;

$border-radius-1: 1.4rem;

// ----- Other -----

$spacer-1: 1rem;

// ----- Basic Layout -----

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    // height: -webkit-fill-available;

    // background-color: #a1acf4;
    // background-color: #333;
    box-sizing: border-box;
    font-size: 62.5%; /* 1rem = 10px, 10px/16px = 62.5% */

    &::-webkit-scrollbar {
        width: 0.8rem;
        background-color: #333;

        &-thumb {
            background-color: #555;
        }
    }
    scrollbar-color: #555 #333;

    // @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
    // @media only screen and (max-height: $bp-small) and (orientation: landscape) {
    //     transform: rotate(-90deg);
    //     transform-origin: left top;
    //     width: 100vh;
    //     height: 100vw;
    //     overflow-x: hidden;
    //     position: absolute;
    //     top: 100%;
    //     left: 0;
    // }
}

body {
    font-family: $font-sans-1;
    font-size: 1.6rem;
    // background-color: black;
    // background-color: #444;
    //     color: $color-primary-light4;
}

// html {
//     box-sizing: border-box;
//     font-size: 62.5%; /* 1rem = 10px, 10px/16px = 62.5% */
// }

// body {
// min-height: 100vh;
// /* mobile viewport bug fix */
// min-height: -webkit-fill-available;

// font-family: 'Lato', sans-serif;
/* font-family: 'Open Sans', sans-serif; */
// font-family: 'Noto Serif SC', serif;
// color: $landing-color-primary-light4;
// }

// body {
//     margin: 0;
//     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//       'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//       sans-serif;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//   }

//   code {
//     font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//       monospace;
//   }

// $nav-height: clamp(1px, 9vh, 8rem);
$nav-height: 8rem;

@mixin for-phone-portrait {
    @media only screen and (max-width: $bp-small) {
        @content;
    }
}

@mixin for-non-phone {
    @media only screen and (min-width: $bp-small) {
        @content;
    }
}

@mixin fixed-full-screen {
    position: fixed;
    left: 0;
    top: 0;
    // width: 100vw;
    // height: 100vh;
    right: 0;
    bottom: 0;
}

@mixin set-pos-lrtb($position, $left, $right, $top, $bottom) {
    position: $position;
    left: $left;
    right: $right;
    top: $top;
    bottom: $bottom;
}

@mixin set-pos-ltwh($position, $left, $top, $width, $height) {
    position: $position;
    left: $left;
    top: $top;
    width: $width;
    height: $height;
}

@mixin landing-button-1 {
    min-width: 12rem;
    height: 3rem;
    padding: 0 2rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    font-family: $font-sans-1;
    @include fsr-14;

    border-radius: 8px;
    background-color: $landing-color-secondary;
    color: $landing-color-primary;
    user-select: none;
    cursor: pointer;
}

@mixin landing-button-disabled-1 {
    background-color: black;
    border: solid 1px $landing-color-primary-dim-3;
    color: $landing-color-primary-dim-3;
    cursor: default;
}

@mixin error-text {
    text-transform: uppercase;
    @include fsr-16;
    font-weight: bold;
    color: $landing-color-error;
}
