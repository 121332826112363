.bigScroll {
    position: fixed;
    left: 0;
    right: 0;
    top: $nav-height;
    bottom: 0;
    overflow: auto;

    @include for-phone-portrait {
        top: 0;

        .notOnPhone {
            display: none !important;
        }
    }

    @include for-non-phone {
        .onlyOnPhone {
            display: none !important;
        }
    }

    .section {
        position: relative;
        width: 100vw;
        max-width: 100%;
        // height: calc(100vh - #{$nav-height});
        height: 500px;
        // display: flex;
        // justify-content: center;
        // align-items: center;

        &__content {
            position: absolute;
            left: 23%;
            top: 8%;
            width: 54%;
            height: 80%;
            text-align: center;
            // background-color: blue;

            @media only screen and (max-width: $bp-small) {
                left: 2rem;
                right: 2rem;
                width: auto;
                // top: 4rem;
                top: 0.5rem;
                bottom: 2rem;
                height: auto;
            }

            &--header,
            .header {
                position: relative;
                display: inline-block;
                @include font-serif-1;
                @include fsr-35;

                @include for-phone-portrait {
                    font-size: 3rem;
                }

                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -0.8rem;
                    height: 2px;
                    background-color: $landing-color-secondary;
                }
            }

            .pageNum {
                margin-top: 1.4rem;
                font-size: 1.2rem;
                // color: $landing-color-primary-dim-2;
                color: $landing-color-secondary;
            }

            &--text,
            .bodyText {
                margin-top: 3.5rem;
                @include fsr-18;
                text-align: left;

                @include for-phone-portrait {
                    font-size: 1.4rem;
                }

                p {
                    margin-bottom: 2rem;
                }
                // color: yellow;
            }

            &--home {
                .logoBlock {
                    margin-bottom: 6vmin;

                    &__logo {
                        @include font-serif-1;
                        @include fsr-170;

                        @media only screen and (max-width: $bp-small) {
                            margin-top: 6rem;
                            font-size: 20vw;
                        }
                    }

                    &__dictDef {
                        position: relative;
                        top: -2rem;
                        @include fsr-20;
                        opacity: 0.8;

                        .pronounce {
                            @include font-serif-1;
                            font-size: 2.3rem;

                            &--1 {
                                font-weight: 700;
                            }

                            .eBar {
                                font-size: 2rem;
                            }
                        }

                        .origin {
                            font-style: italic;
                        }

                        .break {
                            display: none;
                        }

                        @media only screen and (max-width: $bp-small) {
                            top: 0;
                            margin-bottom: 4rem;

                            font-size: 1.6rem;

                            .pronounce {
                                font-size: 2rem;

                                .eBar {
                                    font-size: 1.6rem;
                                }
                            }

                            .break {
                                display: inline;
                            }
                        }
                    }
                }
            }

            &--app {
                left: 50%;
                transform: translateX(-50%);
                top: 0;
                width: 80%;
                max-width: 100rem;
                height: 100%;
                // padding: 10% 0;
                // display: flex;
                // background-color: darkgrey;

                .section__content--header {
                    display: none;
                }

                @include for-phone-portrait {
                    left: 3rem;
                    transform: translateX(0);
                    right: 3rem;
                    width: auto;
                    // top: 4rem;
                    // height: auto;

                    .section__content--header {
                        display: inline-block;
                    }
                }

                .carousel {
                    @include set-pos-lrtb(absolute, 0, 0, 2rem, 5%);
                    border-radius: 5em;
                    border: solid 2px $color-grey-mid-1;
                    // background-color: yellow;
                    background-color: black;

                    @include for-phone-portrait {
                        border-radius: 3rem;
                        @include set-pos-lrtb(absolute, 0, 0, 8rem, 2.5rem);
                    }

                    // &-root {
                    //     height: 100%;
                    // }

                    // &-slider {
                    //     height: 100%;

                    .slider-wrapper {
                        height: 100%;

                        .slider {
                            height: 100%;

                            .zoomableSlide {
                                @include set-pos-lrtb(absolute, 0, 0, 0, 0);

                                .closeBtn {
                                    position: absolute;
                                    right: 3rem;
                                    top: 3rem;
                                    color: $landing-color-secondary;
                                    display: none;
                                }

                                @include for-phone-portrait {
                                    padding: 0 2rem 2rem 2rem;

                                    &--zoomed {
                                        padding: 0;

                                        .bodyText {
                                            display: none;
                                        }

                                        .rightFrame {
                                            // background-color: black !important;
                                            background-position: center !important;
                                        }

                                        .closeBtn {
                                            display: block;
                                        }
                                    }
                                }
                            }

                            .slide {
                                height: 100%;
                                padding: 2rem 2rem 4rem 2rem;
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                // background-color: blue;

                                @include for-phone-portrait {
                                    height: 100%;
                                    padding: 1.6rem 1.5rem 3rem 1.5rem;
                                    flex-direction: column;
                                    justify-content: stretch;

                                    .header {
                                        display: none;
                                    }

                                    .pageNum {
                                        display: none;
                                    }

                                    .bodyText {
                                        margin-top: 0;
                                        // font-size: 1.4rem;
                                    }
                                }

                                .leftFrame {
                                    // width: 60%;
                                    // flex-grow: 0.5;
                                    // max-width: 40%;
                                    flex: 0.4;
                                    padding-top: 4rem;
                                    // display: flex;
                                    // flex-direction: column;
                                    // justify-content: center;
                                    // background-color: orange;

                                    // .bodyText {
                                    //     // text-align: center;
                                    // }
                                    @media only screen and (max-width: $bp-small) {
                                        flex: 0;
                                        padding-top: 0;

                                        .bodyText {
                                            text-align: center;
                                        }
                                    }
                                }

                                .rightFrame {
                                    margin-left: 5%;
                                    // width: 40%;
                                    flex: 0.5;
                                    // background-color: red;

                                    // background: no-repeat center top/contain red;
                                    background: no-repeat center top/contain;

                                    // &.zoomed {
                                    //     @include fixed-full-screen;
                                    //     background-position: center;
                                    // }

                                    @include for-phone-portrait {
                                        flex: 1;
                                        margin-left: 0;
                                    }
                                }

                                &--0m .rightFrame {
                                    background-image: url(https://storage.googleapis.com/gratia-media/web/images/Phone-Man1-c7h%403x.png);
                                }

                                &--1m .rightFrame {
                                    background-image: url(https://storage.googleapis.com/gratia-media/web/images/Phone-Man2-c7h%403x.png);
                                }

                                &--2m .rightFrame {
                                    background-image: url(https://storage.googleapis.com/gratia-media/web/images/Phone-Man3-c7h%403x.png);
                                }

                                &--3m .rightFrame {
                                    background-image: url(https://storage.googleapis.com/gratia-media/web/images/Phone-Man4-c7h%403x.png);
                                }

                                &--4m,
                                &--3f {
                                    flex-direction: column;
                                    justify-content: flex-start;

                                    .leftFrame {
                                        flex: 0;
                                    }

                                    .rightFrame {
                                        flex: 0;
                                        margin-left: 0;
                                        text-align: center;

                                        .bodyText {
                                            display: inline-block;
                                            margin-top: 4rem;
                                            width: 50%;
                                            text-align: center;

                                            @include for-phone-portrait {
                                                width: 90%;
                                            }
                                        }
                                    }

                                    @include for-phone-portrait {
                                        .header {
                                            font-size: 2.4rem;
                                            display: inline-block;
                                        }

                                        // .pageNum {
                                        //     display: block;
                                        //     // margin-bottom: 3rem;
                                        // }
                                    }
                                }

                                &--0f .rightFrame {
                                    background-image: url(https://storage.googleapis.com/gratia-media/web/images/Phone-Woman1-c7h%403x.png);
                                }

                                &--1f .rightFrame {
                                    background-image: url(https://storage.googleapis.com/gratia-media/web/images/Phone-Woman2-c7h%403x.png);
                                }

                                &--2f .rightFrame {
                                    background-image: url(https://storage.googleapis.com/gratia-media/web/images/Phone-Woman3-c7h%403x.png);
                                }
                            }
                        }
                    }
                    // }
                }
            }

            &--events {
                // left: 55%;
                // top: 10%;
                // width: 40%;
                // height: 90%;

                // @media only screen and (max-width: $bp-small) {
                //     left: 45%;
                //     width: 50%;
                // }

                display: flex;
                flex-direction: column;

                .split {
                    margin-top: 4rem;
                    flex: 1;
                    display: flex;

                    @include for-phone-portrait {
                        margin-top: 0;
                    }

                    &__left {
                        flex: 55;
                        height: 100%;
                        // background-color: red;
                    }

                    &__right {
                        flex: 45;
                        height: 100%;
                        // background-color: blue;
                    }
                }
            }

            &--contact {
                left: 34%;
                top: 10%;
                width: 32%;
                height: 90%;

                p {
                    padding: 0 3rem;
                    text-align: center;
                }

                .contactForm {
                    .textBox {
                        width: 100%;
                        height: 25vh;
                        border-radius: 1rem;
                        border: solid 1px $landing-color-primary;
                        background-color: black;
                    }

                    .sendBtn {
                        margin-top: 2rem;
                        @include landing-button-1;
                    }
                }

                @include for-phone-portrait {
                    left: 2rem;
                    width: auto;
                    top: 0;
                    height: auto;
                }
            }

            &--profile {
                @include set-pos-ltwh(absolute, 0, 0, 100%, 100%);
                // background-color: #444;
                display: flex;
                flex-direction: column;
                align-items: center;

                .phoneSimContainer {
                    margin-top: 1%;
                    width: 45vh;
                    height: 85vh;

                    @include for-phone-portrait {
                        margin: 0;
                        width: 100vw;
                        height: 100vh;
                    }

                    .phoneSim {
                        // background-color: $app-color-bg;
                        // background-color: black;
                        background-color: rgba(black, 0.1);
                        color: $landing-color-primary;

                        @include for-phone-portrait {
                            background-color: transparent;
                        }

                        .survey {
                            height: 100%;
                            padding: 2.5vmin;

                            @include for-phone-portrait {
                                padding-top: 1rem;
                            }

                            &Page {
                                &__heading {
                                    font-size: clamp(1rem, 2.3vmin, 4rem);
                                }

                                &__subHeading {
                                    font-size: clamp(1rem, 1.7vmin, 4rem);
                                }

                                .surveyItem {
                                    .dateTime .react-calendar__tile--active {
                                        background: $landing-color-secondary !important;
                                        color: $landing-color-primary;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &--billing {
                @include set-pos-ltwh(absolute, 0, 0, 100%, 100%);
                // background-color: #444;
                display: flex;
                flex-direction: column;
                align-items: center;

                .phoneSimContainer {
                    margin-top: 1%;
                    width: 45vh;
                    height: 85vh;

                    @include for-phone-portrait {
                        margin: 0;
                        width: 100vw;
                        height: 100vh;
                    }

                    .phoneSim {
                        // background-color: $app-color-bg;
                        // background-color: black;
                        background-color: rgba(black, 0.1);
                        color: $landing-color-primary;

                        @include for-phone-portrait {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
}
