.frontDoor {
    $fD: &;
    @include fixed-full-screen;

    &__pane {
        @include set-pos-ltwh(absolute, 0, 0, 50%, 100%);

        &--left {
            left: 0;
            background: repeat right top
                url(../../assets/images/front-door-a3_left.jpg);
        }

        &--right {
            left: 50%;
            background: repeat left top
                url(../../assets/images/front-door-a3_right.jpg);
        }

        #{$fD}.show-enter & {
            transition: transform 1s;

            &--left {
                transform: translateX(-100%);
            }

            &--right {
                transform: translateX(100%);
            }
        }

        #{$fD}.show-enter-active &,
        #{$fD}.show-enter-done & {
            &--left {
                transform: translateX(0);
            }

            &--right {
                transform: translateX(0);
            }
        }

        #{$fD}[class*='show-exit'] & {
            transition: transform 1s 0.5s;

            &--left {
                transform: translateX(-100%);
            }

            &--right {
                transform: translateX(100%);
            }
        }
    }

    &__container {
        // position: absolute;
        // left: 50%;
        // transform: translateX(-50%) scale(100%);
        // top: 50%;
        // width: 40%;
        // max-width: 50rem;
        // width: 28vw;
        // max-width: 40rem;
        // height: 30%;
        // border-radius: 5rem;
        // border: solid 2px rgba($landing-color-secondary, 0.3);
        // background-color: black;
        // transform: translate(-50%, -50%);
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        //     width: 28%;
        //     max-width: 40rem;

        $translateYForMobile: -15%;

        @include for-phone-portrait {
            // .authForm {
            transform: translateY($translateYForMobile);
            // }
        }

        #{$fD}.show-enter & {
            transition: transform 0.75s 0.5s;
            // transform: translate(-50%, -50%) scale(0);
            transform: scale(0);

            @include for-phone-portrait {
                transform: scale(0) translateY($translateYForMobile);
            }
        }

        #{$fD}.show-enter-active &,
        #{$fD}.show-enter-done & {
            // transform: translate(-50%, -50%) scale(1);
            transform: scale(1);

            @include for-phone-portrait {
                transform: scale(1) translateY($translateYForMobile);
            }
        }

        #{$fD}[class*='show-exit'] & {
            transition: transform 1s;
            // transform: translate(-50%, -50%) scale(0.001);
            transform: scale(0.001);

            @include for-phone-portrait {
                transform: scale(0.001) translateY($translateYForMobile);
            }
        }

        .centerBox {
            // padding: 5rem;
            // background-color: orange;
            position: relative;
            width: 30vw;
            max-width: 40rem;
            padding: 5rem 0 5rem 0;
            border-radius: 5rem;
            overflow: hidden;
            // text-align: center;
            text-align: center;

            box-shadow: 1rem 1rem 4rem rgba(black, 0.5);
            color: white;

            @include for-phone-portrait {
                width: 80vw;
            }

            &__bg {
                @include set-pos-lrtb(absolute, 0, 0, 0, 0);
                background-color: black;
                opacity: 0.6;
            }

            .headline {
                position: relative;
                // margin-bottom: 5rem;
                font-size: 2.2rem;
                // color: $color-feature-1;
                color: $color-bigScroll-fg-1;
                text-transform: uppercase;

                letter-spacing: 0.6rem;
                padding-left: 0.6rem;
            }

            &__contents {
                position: relative;
                padding: 0 5rem 0 5rem;

                // .rememberMe .fancyInput__checkbox--label {
                //     font-size: 1.2rem;
                //     color: $landing-color-primary-dim;
                //     user-select: none;
                // }

                &--signIn {
                    margin-top: 5rem;
                }

                .smallText {
                    font-size: 1.4rem;
                    color: $landing-color-primary-dim;
                }

                .clickableLink {
                    font-size: 1.2rem;
                    color: $landing-color-primary-dim;
                    text-decoration: underline;
                    user-select: none;
                    cursor: pointer;

                    &--forgotPassword,
                    &--requestAnotherCode {
                        margin: 0.6rem 0 0 1rem;
                        text-align: left;
                    }

                    // &--cancel {
                    //     margin-top: 2rem;
                    // }
                }

                .fancyInput {
                    &__text,
                    &__password {
                        &.spaceBefore {
                            margin-top: 4rem;
                        }

                        &--input {
                            display: block;
                            // width: 25vw;
                            width: 100%;
                            // max-width: 30rem;
                            padding: 1rem;
                            border-radius: 1rem;
                            border: none;
                            outline: none;
                            background-color: $color-feature-1;
                            font-size: 1.4rem;

                            &::placeholder {
                                color: $color-grey-mid-1;
                            }
                        }

                        &--label {
                            // color: $color-feature-2;
                            color: $color-bigScroll-fg-1;
                        }

                        &--error {
                            font-size: 1.2rem;
                            @include color-error-1;
                        }
                    }

                    &__checkbox {
                        margin: 0.6rem 0 0 1rem;

                        &--label {
                            // font-size: 1.4rem;
                            // color: $color-feature-1;
                            // color: $color-bigScroll-fg-1;
                            // color: yellow;
                            font-size: 1.2rem;
                            color: $landing-color-primary-dim;
                            // cursor: pointer;
                        }

                        &--box {
                            margin-left: 1rem;
                            width: 1.6rem;
                            height: 1.6rem;
                            border: solid 1px $color-feature-1;
                            font-size: 1.4rem;
                            color: $color-feature-1;
                        }
                    }
                }

                .buttonWithSpinner {
                    display: inline-grid;
                    grid-template-rows: min-content;
                    grid-template-columns: 1fr;

                    &--genPasswordResetCode,
                    &--resetPassword {
                        margin-top: 2rem;
                    }

                    .spinner {
                        grid-area: 1 / 1 / 2 / 2;
                        z-index: 1;
                        justify-self: center;
                        align-self: center;
                    }

                    .fancyButton {
                        grid-area: 1 / 1 / 2 / 2;
                        z-index: 2;

                        // background-color: $landing-color-secondary;
                        // color: $landing-color-primary;

                        // &.disabled {
                        //     opacity: 0.3;
                        //     cursor: default;
                        // }
                    }
                }

                .errors {
                    height: 5rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    // background-color: #000077;

                    .error {
                        // margin: 2rem 0;
                        text-align: center;
                        // font-size: 2.2rem;
                        font-size: 1.8rem;
                        // color: $color-error-1;
                        color: red;
                    }
                }

                // &__modeSwitchBtn {
                //     display: inline-block;
                //     margin-top: 3rem;
                //     padding-bottom: 0.3rem;
                //     // text-align: center;
                //     font-size: 1.6rem;
                //     border-bottom: solid 1px;
                //     cursor: pointer;
                // }
            }
        }
    }
}
