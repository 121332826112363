.switch {
    display: flex;
    align-items: center;

    .label {
        flex: 1 1 0px;
        font-size: 1.2rem;
        color: $landing-color-primary;

        &--false {
            text-align: right;
        }
    }

    &--undefined {
        opacity: 0.5;
    }

    &__button {
        position: relative;
        margin: 0 1rem;
        width: 4rem;
        height: 2rem;
        box-sizing: content-box;
        border-radius: 2rem;
        border: solid 1px $landing-color-primary;
        cursor: pointer;

        .thumb {
            position: absolute;
            left: 0;
            top: 0;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            background: $landing-color-primary-dim-3;
            transition: left 0.1s;

            &--true {
                left: 2rem;
            }
        }
    }
}
