.dragDropList {
    &--debug {
        // background-color: #aaf;
        border: solid 2px red;
    }

    ul {
        list-style: none;
    }

    &__header {
        padding-left: 3rem;
        display: flex;
        align-items: center;

        &Label {
            font-size: 1.8rem;
        }
    }

    &__item {
        margin: 1rem 2rem;
        padding: 1rem;
        // background-color: #d8d8e8;
        border-radius: 1rem;
        border: solid 1px #aaa;

        &--debug {
            border: dashed 1px orange;
        }
        // display: grid;
        // grid-template-columns: 1fr min-content;

        //     margin-left: 2rem;

        //     // &::marker {
        //     //     display: none;
        //     // }

        &Label {
            // grid-area: 1 / 1 / 2 / 3;
            display: flex;

            // .label {
            //     flex-grow: 1;
            // }
        }
        
        .arrayControls {
            // display: inline-flex;
            text-align: center;
            padding: 2rem 0;
            // margin: 1rem 0.5rem 0 0;
            // padding-bottom: 1rem;
        }
    }

    // &--images {
    //     display: inline-block;
    //     padding: 1rem;

    //     border-radius: 1rem;
    //     border: solid 1px black;
    //     background-color: yellow;
    // }

    // &__item {
    //     &--image-1 {
    //         background-color: lightblue;
    //     }
    // }
}
