.panel {
    display: inline-block;
    position: relative;
    padding: 1rem;

    // padding: 1rem;
    background-color: $panel-bg-color-1;

    border-radius: $border-radius-1;
    border: solid 1px $panel-border-color-1;
    // color: $color-primary-1;
    // box-shadow: 1rem 1rem 4rem rgba(black, 0.5);

    overflow: auto;

    &.collapsed {
        width: 5rem;
    }

    &__children {
        // background-color: yellow;
        height: 100%;
    }

    &__controls {
        // background-color: blue;

        &--triangle {
            // background-color: orange;
            position: absolute;
            right: 0.5rem;
            bottom: 0.5rem;
            width: 1.5rem;
            height: 1.5rem;

            svg {
                width: 100%;
                height: 100%;

                path {
                    fill: $color-primary-trn-2;
                }
            }
            cursor: pointer;
            user-select: none;
            transform: rotate(180deg);
            transition: transform 0.2s;

            &.collapsed {
                // background-color: red;
                transform: rotate(0deg);
            }
        }
    }
}
