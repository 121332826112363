.imagePicker {
    // background: #444;

    &__image {
        position: relative;
        padding-top: 100%;
        border-radius: 1.5rem;
        border: solid 1px $landing-color-primary-dim-3;

        &--bg {
            @include set-pos-ltwh(absolute, 0, 0, 100%, 100%);
            border-radius: 1.5rem;
            background: no-repeat center/contain $app-color-bg-dim;
        }

        &--noImage {
            @include set-pos-ltwh(absolute, 0, 0, 100%, 100%);
            display: flex;
            justify-content: center;
            align-items: center;
            color: $landing-color-primary-dim-3;
        }

        &--spinner {
            @include set-pos-ltwh(absolute, 0, 0, 100%, 100%);
            display: flex;
            justify-content: center;
            align-items: center;
            // color: $landing-color-primary-dim-3;
        }
    }

    &__buttons {
        margin-top: 2rem;
        // background: #009;

        .button {
            @include landing-button-1;
            padding: 0;

            label {
                width: 100%;
                cursor: pointer;
            }
        }

        .label {
            @include landing-button-1;
        }

        input {
            display: none;
        }
    }
}
